import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
// import OurProjects from "components/OurProjects"
import SectionContact from "components/SectionContact"

import {
  Hero,
  Content,
  Location,
  Docs,
  SoldBlur,
} from "page_components/investment"

import { isUserLoggedIn } from "utils/functions"

const Product = ({ pageContext }) => {
  const auth = isUserLoggedIn()
  const isBrowser = typeof window !== "undefined"

  if (!auth && isBrowser) {
    navigate("/")
    return null
  }

  return (
    <Layout
      seo={{
        title: "Trust Investment Projekt 23",
        noIndex: true,
      }}
    >
      <Breadcrumbs title="Projekt 23" />
      <Hero data={pageContext.data.data} />
      <Content data={pageContext.data.data} />
      <Location data={pageContext.data.data} />
      <Docs data={pageContext.data.data} />
      {/* <OurProjects data={data?.allWpPortfolio?.nodes} /> */}
      <SectionContact />
      <SoldBlur />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPortfolio(limit: 2, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolio {
          city
          address
          area
          shortDescription
        }
      }
    }
  }
`

export default Product
